.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height:100vh;  
}

img {
    width: 60%;
    height: 60%;
    margin-left: auto;
    margin-right: auto;
}

h4 {
    text-align: center;
}