.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    min-height: 100vh !important;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}
#sidebar-wrapper{
    min-height: 100vh !important;
    width: 100vw;
    margin-left: -1rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}
#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

.list-item, .list-item:link, .list-item:active{
    /* margin-left: 10px; */
    color: #9d9d9d;
    /* border-bottom: solid #9d9d9d 1px; */
    font-size: 13px;
}
.list-item:hover {
    background-color: #000;
    color: #9d9d9d;
}

.addons-item, .addons-item:link, .addons-item:active, .addons-item:hover {
    color: #000;
}

html, body, #root, .App, .container-fluid {
    height: 100%;
}

.deactiveRowStyle {
    background-color: lightgrey !important;
}

.editClassRowElement:hover .editor {
    display: block !important;
}

.hidden { display:none; }